
import { Component, Vue } from 'vue-property-decorator'
import { SoilHumidityDetail, GroupData, LocationInfo, GroupDetails } from '../../types/soilHumidity.d'
import { mapKey } from '@/utils/config'
import AMapLoader from '@amap/amap-jsapi-loader'
import { drawPolygon } from '@/utils/formatData'

const icon = require('@/assets/icon/soilHumidity/normal.svg')
@Component({
  name: 'DetailList'
})
export default class DetailList extends Vue {
  private info = {
    deviceNumber: '',
    deviceStatus: ''
  }

  private loading = false
  private dataList: Array<SoilHumidityDetail> = []
  page = 1
  size = 10
  total = 0
  private groupShow = false
  private groupLoading = false
  private groupData: Array<GroupData> = []
  private addLine = '0'
  private oldData: { [key: number]: GroupData } = {}
  private deviceList: Array<any> = []
  private deviceIdList: Array<any> = []
  private mapShow = false
  private map: AMap.Map | any = null
  private marker: AMap.Marker | any = null
  private ploygons: Array<AMap.Polygon> = []
  private locationInfo: LocationInfo = {
    id: '',
    resourcesLocationList: []
  }

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getData()
  }

  destroyed (): void {
    if (this.map.getAllOverlays('marker').length > 0) {
      this.map.getAllOverlays('marker').map((item: AMap.Marker) => {
        item.off('click', () => {
          // 清除marker事件
        })
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', () => {
          // 清除ploygons事件
        })
      })
    }
    if (this.map) {
      this.map.off('click', null)
      this.map.destroy()
    }
  }

  // 获取列表数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<SoilHumidityDetail> }>(this.$apis.soilHumidity.selectSoilWithPage, {
      projectId: this.projectId,
      page: this.page,
      size: this.size,
      deviceNumber: this.info.deviceNumber,
      deviceStatus: this.info.deviceStatus
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 设备分组列表
  getGroupData () {
    this.groupLoading = true
    this.$axios.get<{ total: number; list: Array<GroupData> }>(this.$apis.soilHumidity.selectSoilGroupByList, {
      projectId: this.projectId
    }).then(res => {
      this.groupData = res.list || []
    }).finally(() => {
      this.groupLoading = false
    })
  }

  // 获取可绑定设备列表
  getDeviceList () {
    this.$axios.get(this.$apis.soilHumidity.selectNotGroupDeviceList, {
      projectId: this.projectId
    }).then((res) => {
      this.deviceList = res
    })
  }

  // 搜索
  onSearch () {
    this.page = 1
    this.getData()
  }

  // 分组弹窗打开
  onGroup () {
    this.getGroupData()
    this.getDeviceList()
    this.groupShow = true
  }

  // 分组弹窗关闭
  groupClose () {
    this.addLine = '0'
    this.groupShow = false
    this.groupData = []
    this.deviceList = []
  }

  // 设备分组编辑
  onUpdate (row: GroupData, index: number) {
    if (this.addLine === '0') {
      row.groupDetails.forEach(item => {
        this.deviceList.push({
          deviceId: item.deviceId,
          deviceName: item.deviceName,
          deviceNumber: item.deviceNumber
        })
        this.deviceIdList.push(item.deviceId)
      })
      this.groupData[index] = {
        ...row,
        isInput: true
      }
      this.oldData[index] = JSON.parse(JSON.stringify(row))
      this.addLine = '1'
      this.$set(this.groupData, index, this.groupData[index]) // 重新渲染表格数据
    } else {
      this.$message.warning('请先保存！')
    }
  }

  // 设备分组删除
  onDelete (row: GroupData) {
    if (this.addLine === '0') {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$axios.post(this.$apis.soilHumidity.deleteDataSoilGroup, { groupId: row.groupId }).then(() => {
          this.$message.success('删除成功')
          this.getGroupData()
          this.getDeviceList()
        }).finally(() => {
          this.loading = false
        })
      })
    } else {
      this.$message.warning('请先保存！')
    }
  }

  // 设备分组新增分组
  addGroup () {
    if (this.addLine === '0') {
      this.addLine = '1'
      this.groupData.push({
        groupName: '',
        longitude: '',
        latitude: '',
        isInput: true,
        groupDetails: []
      })
      this.$nextTick(() => {
        ;(this.$refs.addTable as any).bodyWrapper.scrollTop = (this.$refs
          .addTable as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    } else {
      this.$message.warning('请先保存！')
    }
  }

  // 保存分组
  onSave (row: GroupData) {
    if (this.deviceIdList.length === 0 || row.groupName === '') {
      this.$message.warning('必填字段为空！')
    } else {
      const list = this.deviceIdList.map(item => {
        return { deviceId: item }
      })
      if (list.length > 10 || list.length < 2) {
        this.$message.warning('关联设备不能多于10个且不能少于2个！')
        return
      }
      const url = row.groupId ? this.$apis.soilHumidity.updateSoilGroup : this.$apis.soilHumidity.insertSoilGroup
      const info = row.groupId ? {
        groupId: row.groupId,
        groupName: row.groupName,
        projectId: this.projectId,
        groupDetails: list
      } : {
        groupName: row.groupName,
        projectId: this.projectId,
        groupDetails: list
      }
      this.$axios.post(url, info).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      }).finally(() => {
        this.addLine = '0'
        this.deviceIdList = []
        this.getGroupData()
        this.getDeviceList()
      })
    }
  }

  // 取消编辑
  onCancel (row: GroupData, index: number) {
    this.addLine = '0'
    if (row.groupId) {
      // 是否为编辑
      this.groupData[index].isInput = false
      this.groupData[index].groupName = this.oldData[index].groupName
      this.groupData[index].groupDetails = this.oldData[index].groupDetails
    } else {
      this.groupData.splice(index, 1)
    }
    this.getDeviceList()
    this.deviceIdList = []
  }

  // 地图保存
  onMapSubmit () {
    if (this.locationInfo.resourcesLocationList.length > 0) {
      if (this.locationInfo.id) {
        this.$axios.post(this.$apis.soilHumidity.updateSoilGroupPoint, {
          groupId: this.locationInfo.id,
          longitude: this.locationInfo.resourcesLocationList[0].longitude,
          latitude: this.locationInfo.resourcesLocationList[0].latitude
        }).then(() => {
          this.mapShow = false
          this.getGroupData()
        })
      } else {
        this.mapShow = false
      }
    } else {
      this.$message({
        message: '请打点',
        type: 'warning'
      })
    }
  }

  // 地图打开
  onMap (row: any) {
    this.locationInfo = {
      id: row.groupId || '',
      resourcesLocationList: [{
        latitude: row.latitude,
        longitude: row.longitude
      }] || []
    }
    this.mapShow = true
    this.loadMap()
  }

  // 地图初始化
  loadMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      this.map = new AMap.Map('map', {
        zoom: 11,
        doubleClickZoom: false
      })
      this.projectId && this.getProjectLocation() // 获取电子围栏
      if (this.locationInfo.resourcesLocationList.length > 0) {
        this.markerAdd([this.locationInfo.resourcesLocationList[0].longitude, this.locationInfo.resourcesLocationList[0].latitude])
      }
      this.map.on('click', (e: any) => {
        this.markerAdd([e.lnglat.getLng(), e.lnglat.getLat()])
      })
    })
  }

  // 获取项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.ploygons = drawPolygon(this.map, res.projectLocation, null, (e: any) => {
        this.markerAdd([e.lnglat.getLng(), e.lnglat.getLat()])
      })
    })
  }

  // 地图点位
  markerAdd (position: [number, number]) {
    // 删除上一个点
    if (this.marker) {
      this.marker.setMap(null)
      this.marker = null
    }
    this.marker = new AMap.Marker({
      icon: new AMap.Icon({
        // 图标的取图地址
        image: icon
      }),
      offset: new AMap.Pixel(-20, -40),
      position: [Number(position[0]), Number(position[1])]
    })
    this.map.add(this.marker)
    this.locationInfo.resourcesLocationList = [{
      longitude: position[0],
      latitude: position[1]
    }]
  }

  // 地图关闭
  closeDialogMap () {
    this.mapShow = false
    this.locationInfo = {
      id: '',
      resourcesLocationList: []
    }
  }

  toHistory (deviceId: string) {
    this.$router.push({ name: 'soilHumidityListHistory', params: { projectId: this.projectId, id: deviceId, type: '1' } })
  }
}
